<template>
  <GmapMap ref="gmap" :center="center" v-show="!getIssuesIsLoading" :zoom="zoom" :options="options"
    style="width: 100%; height: 100%;">
    <!-- Creacte GmapCluster -->
    <GmapCluster @click="selectedIssueUser($event)">
      <GmapMarker :title="issue.id" :key="issue.id" v-for="(issue) in getIssuesIssues" :position="issue.position"
        :shape="shape" class="test-m" :icon="getMarkerIcon(issue)" @click="selectedIssue(issue)" />
    </GmapCluster>

    <v-dialog v-model="dialog" max-width="480">
      <v-card>
        <div :key="issue.id" @click="dialog = false;selectedIssue(issue)" v-for="issue in issuesUsers"
          class="modal-cluster">
          <div class="d-flex justify-cente ma-4" >
            <!-- <v-img src="/img/theme/app-logo.png" height="80" width="80"/> -->
            <img :src="issue.issueType.iconUrl" height="80" width="80" alt="Imagen del tipo de issue">
          </div>
          <v-card-text>
            <div class="row">
              <div class="col-8">
                <h2 class="worflow-name" style="color: #9d2449; font-weight: 900; margin-top: 4px;">
                  {{ issue.workflow.name }}
                </h2>
              </div>
              <div class="col-4">
                <v-chip dark :color="issue.issueStatus.color">{{
                issue.issueStatus.name
                }}</v-chip>
              </div>
            </div>

            <div style="margin-top: 12px;" class="mb-2 dark-grey--text">Reportada
              <span v-if="issue" class="ml-2 font-weight-light"> hace {{
              issue.createdAt | dateFromNow
              }}</span>
            </div>

            <div class="black--text">
              Por {{ issue.reporter.displayName }}
            </div>

          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="color: rgb(157, 36, 73); font-weight: 500;" text @click="dialog = false;">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </v-row> -->
  </GmapMap>
</template>
<script>
import { to } from '@/helpers'
import { dateFromNow } from '@/mixins'
import { ISSUES_GET_ISSUE } from '@/store/actions.type'
import { gmapApi } from 'vue2-google-maps'
import { mapGetters } from 'vuex'
export default {
  name: 'IssuesMap',
  props: {},
  data() {
    return {
      options: {
        clickableIcons: true,
        mapTypeControl: true,
        fullscreenControl: true,
        streetViewControl: true,
        zoomControl: true
      },
      zoom: 13,
      center: {
        lat: 19.4101,
        lng: -99.1699
      },
      shape: {
        coords: [50, 50, 50],
        type: 'circle'
      },
      issueId: null,
      dialog: false,
      issuesUsers: null
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters(['getIssuesIssues', 'getIssuesIsLoading', 'getIssuesIssue'])
  },
  methods: {
    getMarkerIcon(issue) {
      return {
        url: issue.issueType.iconUrl,
        size: { width: 48, height: 56, f: 'px', b: 'px' },
        scaledSize: { width: 48, height: 56, f: 'px', b: 'px' },
        shape: this.shape
      }
    },
    selectedIssueUser(issue) {
      const issueClusters = issue.getMarkers().map((marker) => this.getIssuesIssues.find((item) => item.id === marker.title))
      this.dialog = true; this.issuesUsers = issueClusters
    },
    selectedIssue(issue) {
      this.issueId = issue.id
      this.getIssue()
    },
    async getIssue() {
      const [err] = await to(this.$store.dispatch(ISSUES_GET_ISSUE, this.issueId))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  mixins: [dateFromNow],
  watch: {
    getIssuesIssues: function () {
      this.$refs.gmap.$mapPromise.then(map => {
        if (this.getIssuesIssues.length > 0) {
          const bounds = new this.google.maps.LatLngBounds()

          if (this.getIssuesIssues.length < 1) {
            bounds.extend({
              lat: 19.4326,
              lng: -99.1332
            })
          } else {
            for (const issue of this.getIssuesIssues) {
              const geo = issue.center.geometry
              if (
                geo &&
                geo.type &&
                geo.type === 'Point'
              ) {
                bounds.extend({
                  lat: geo.coordinates[1],
                  lng: geo.coordinates[0]
                })
              }
            }
          }
          this.center = bounds.getCenter()
          map.fitBounds(bounds)
        }
      })
    },
    getIssuesIssue: function () {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new this.google.maps.LatLngBounds()
        bounds.extend(this.getIssuesIssue.position)
        this.center = bounds.getCenter()
        map.fitBounds(bounds)
      })
    }
  }
}
</script>
<style scoped>
.test-m {
  padding: 1000px;
}

.modal-cluster:hover {
  background-position: 100% 0%;
  background-color: rgba(119, 125, 123, 0.7);
  opacity: .9;
  cursor: pointer;
}

.modal-cluster {
  border-top: solid rgba(119, 125, 123, 0.7) 1px;
  opacity: .9;
  display: flex;
  flex-basis: 150px;
  flex-grow: 1;
  padding: 8px;
}

.worflow-name{
  overflow: hidden;
  white-space: nowrap;
}

</style>
